import { useFormContext } from "react-hook-form"
import { blank, classNames, lang, t } from '@/components/common/Helpers'
import LaravelErrorOutput from "./LaravelErrorOutput"
import { isString } from 'lodash'

export default function Select({ label, name, required, options, defaultValue, emptyOption = false, firstOption = null, disabled = false, multiple = false, className = '', submitErrors = null}) {

    const { register, formState: { errors }, setValue } = useFormContext()

    const inputDefaultClasses = classNames('block w-full bg-gray-50 border-0 border-transparent border-b sm:text-md', className)
    const inputCleanClasses = 'focus:border-blue-600 focus:ring-0'
    const inputErrorClasses = 'pr-10 border-red border-red-500 text-red-900 placeholder-red-300 focus:outline-none focus:ring-0 focus:border-red-500'

    const wrapperDefaultClasses = 'mt-1 border-b'
    const wrapperCleanClasses = 'border-gray-300 focus-within:border-blue-600'
    const wrapperErrorClasses = 'border-red-500 focus-within:border-red-500'

    const locale = lang

    const findOption = (option) => {
        if (isString(option)) {
            return option
        }
        return option?.value ? option.value : option.label
    }

    const findLabel = (option) => {
        if (isString(option)) {
            return option
        }
        return option?.label ? option.label : 'Missing Label'
    }

    return (
        <>
            {label &&
                <label htmlFor={name} className={classNames("block text-sm font-medium", !disabled ? "text-gray-700" : "text-gray-400")}>
                    {label}
                </label>
            }
            <div className={classNames(wrapperDefaultClasses, errors && errors[name] ? wrapperErrorClasses : wrapperCleanClasses)}>
                <select
                    {...register(name, { required })}
                    defaultValue={defaultValue}
                    className={classNames(inputDefaultClasses, errors && errors[name] ? inputErrorClasses : inputCleanClasses)}
                    disabled={disabled}
                    multiple={multiple}
                >
                    {emptyOption && <option value=""></option>}
                    {firstOption && <option value={firstOption.value}>{firstOption.label}</option>}
                    {options.map((option, i) => (
                            <option key={`${name}_${i}`} 
                                value={findOption(option)} 
                                disabled={option?.disabled ? 'disabled' : false} 
                                className={(option?.isHeading ? 'font-semibold text-gray-400' : '')}>
                                    {findLabel(option)}
                            </option>
                    ))}
                </select>
            </div>
            {
                errors && errors[name] &&
                <p className="mt-2 text-sm text-red-600">
                    {errors[name]?.type === 'required' ? (
                        <>
                            {blank(label) || (typeof locale === 'string' && locale.slice(0, 2) !== 'en') ? (
                                <span>{t.trans('forms.required')}</span>
                            ) : (
                                <span>{label} is required</span>
                            )}
                        </>
                    ) : (
                        <>
                            {errors[name].message}
                        </>
                    )}
                </p>
            }
            {
                <LaravelErrorOutput submitErrors={submitErrors} name={name} />
            }
        </>
    )
}
